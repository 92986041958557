import React from 'react';

const Logo = () => {
	return (
		<svg version="1.1" x="0px" y="0px" viewBox="0 0 600 80" enableBackground="new 0 0 600 80">
			<g>
				<path
					fill="#B2B2B2"
					d="M204,72.8c0.6,0.7,0.9,1.4,0.9,2.3c0,1-0.3,1.7-0.9,2.3c-0.6,0.6-1.4,0.9-2.4,0.9h-40.2
		c-1,0-1.7-0.3-2.4-0.9c-0.6-0.6-0.9-1.4-0.9-2.4V4.9c0-0.9,0.3-1.7,0.9-2.4c0.6-0.6,1.4-0.9,2.4-0.9h40.2c0.9,0,1.7,0.3,2.4,0.9
		c0.6,0.6,0.9,1.4,0.9,2.4c0,1-0.3,1.7-0.9,2.3c-0.6,0.6-1.4,0.9-2.4,0.9h-36.6v27.5h31.7c0.9,0,1.7,0.3,2.4,0.9
		c0.6,0.6,0.9,1.4,0.9,2.4c0,1-0.3,1.7-0.9,2.3c-0.6,0.6-1.4,0.9-2.4,0.9h-31.7v29.8h36.6C202.6,71.9,203.4,72.2,204,72.8z"
				/>
				<path
					fill="#B2B2B2"
					d="M278.3,2.4c0.7,0.6,1,1.3,1,2.1c0,0.5-0.1,1.1-0.3,1.6l-29.3,69.9c-0.3,0.7-0.7,1.2-1.3,1.6
		c-0.6,0.4-1.2,0.6-1.9,0.6c-0.7,0-1.3-0.2-1.8-0.5c-0.5-0.4-1-0.9-1.3-1.5L214.3,6.4c-0.2-0.4-0.3-1-0.3-1.8c0-0.9,0.3-1.7,1-2.3
		c0.7-0.6,1.4-0.9,2.2-0.9c1.5,0,2.6,0.8,3.3,2.4L246.8,67l26-63.3c0.4-0.7,0.8-1.2,1.4-1.6c0.5-0.4,1.1-0.6,1.7-0.6
		C276.8,1.5,277.6,1.8,278.3,2.4z"
				/>
				<path
					fill="#B2B2B2"
					d="M299.1,77.3c-0.7,0.7-1.5,1-2.4,1c-1,0-1.8-0.3-2.5-0.9c-0.6-0.6-0.9-1.4-0.9-2.5V5c0-1,0.3-1.8,1-2.5
		c0.7-0.6,1.5-0.9,2.5-0.9c0.9,0,1.8,0.3,2.4,1c0.7,0.7,1,1.5,1,2.4v69.9C300.1,75.9,299.8,76.7,299.1,77.3z"
				/>
				<path
					fill="#B2B2B2"
					d="M379.1,2.5c0.5,0.6,0.8,1.3,0.8,2.2v70.1c0,1.1-0.3,2-1,2.6c-0.7,0.6-1.5,0.9-2.4,0.9c-0.5,0-1-0.1-1.5-0.3
		c-0.5-0.2-0.9-0.5-1.2-0.8l-47-63.3v61.5c0,0.8-0.3,1.5-0.9,2.1c-0.6,0.6-1.3,0.9-2.1,0.9c-0.9,0-1.6-0.3-2.2-0.9
		c-0.6-0.6-0.9-1.3-0.9-2.1V4.9c0-1,0.3-1.8,0.9-2.4c0.6-0.6,1.4-0.9,2.2-0.9c1.2,0,2,0.4,2.6,1.2l47.2,63.7V4.7
		c0-0.9,0.3-1.6,0.9-2.2c0.6-0.6,1.3-0.9,2.2-0.9C377.8,1.6,378.5,1.9,379.1,2.5z"
				/>
				<path
					fill="#B2B2B2"
					d="M458.1,10c0,0.7-0.3,1.4-0.8,2.2c-0.7,0.8-1.5,1.2-2.5,1.2c-0.6,0-1.2-0.2-1.8-0.5
		c-5.8-3.9-12.2-5.8-19.2-5.8c-5.9,0-11.3,1.4-16.1,4.2c-4.8,2.8-8.5,6.7-11.3,11.7c-2.7,5-4.1,10.7-4.1,17c0,6.5,1.4,12.3,4.2,17.3
		c2.8,5,6.6,8.9,11.3,11.6c4.8,2.7,10.1,4.1,15.9,4.1c6.9,0,13.3-1.9,19.1-5.7c0.6-0.4,1.2-0.5,1.9-0.5c1,0,1.9,0.4,2.5,1.3
		c0.5,0.6,0.8,1.3,0.8,2.1c0,1.2-0.5,2-1.5,2.6c-2.8,1.9-6.4,3.5-10.5,4.8c-4.2,1.3-8.2,1.9-12.2,1.9c-6.9,0-13.4-1.7-19.2-5
		c-5.9-3.3-10.6-8-14-14c-3.5-6-5.2-12.8-5.2-20.5c0-7.4,1.7-14.1,5.2-20.1c3.4-6,8.1-10.7,13.9-14.1c5.8-3.4,12.3-5.1,19.4-5.1
		c4.1,0,8.1,0.6,12,1.8c3.9,1.2,7.4,2.8,10.6,4.9C457.6,8,458.1,8.9,458.1,10z"
				/>
				<path
					fill="#B2B2B2"
					d="M517.9,72.8c0.6,0.7,0.9,1.4,0.9,2.3c0,1-0.3,1.7-0.9,2.3c-0.6,0.6-1.4,0.9-2.4,0.9h-40.2
		c-1,0-1.7-0.3-2.4-0.9c-0.6-0.6-0.9-1.4-0.9-2.4V4.9c0-0.9,0.3-1.7,0.9-2.4c0.6-0.6,1.4-0.9,2.4-0.9h40.2c0.9,0,1.7,0.3,2.4,0.9
		c0.6,0.6,0.9,1.4,0.9,2.4c0,1-0.3,1.7-0.9,2.3c-0.6,0.6-1.4,0.9-2.4,0.9H479v27.5h31.7c0.9,0,1.7,0.3,2.4,0.9
		c0.6,0.6,0.9,1.4,0.9,2.4c0,1-0.3,1.7-0.9,2.3c-0.6,0.6-1.4,0.9-2.4,0.9H479v29.8h36.6C516.5,71.9,517.3,72.2,517.9,72.8z"
				/>
				<path
					fill="#B2B2B2"
					d="M583.2,6.8c4.8,3.5,8.4,8.1,10.7,13.9c2.4,5.8,3.6,12.2,3.6,19.2c0,7.2-1.2,13.7-3.6,19.5
		c-2.4,5.8-6,10.4-10.7,13.8c-4.8,3.4-10.7,5.1-17.7,5.1h-27.9c-1,0-1.7-0.3-2.4-0.9c-0.6-0.6-0.9-1.4-0.9-2.4V4.9
		c0-0.9,0.3-1.7,0.9-2.4c0.6-0.6,1.4-0.9,2.4-0.9h27.9C572.5,1.6,578.4,3.3,583.2,6.8z M584.1,63c4.3-5.9,6.5-13.6,6.5-23.1
		c0-5.9-0.9-11.3-2.7-16.1c-1.8-4.8-4.7-8.6-8.6-11.5c-3.9-2.9-8.8-4.3-14.7-4.3h-23.2v63.8h23.2C573.2,71.9,579.7,68.9,584.1,63z"
				/>
			</g>
			<rect
				x="0"
				y="0.3"
				transform="matrix(-1 -4.496771e-11 4.496771e-11 -1 79.3651 80)"
				fill="#999999"
				width="79.4"
				height="79.4"
			/>
			<circle fill="#FFFFFF" cx="79.4" cy="40" r="39.7" />
			<path fill="#00615a" d="M79.4,79.7c21.9,0,39.7-17.8,39.7-39.7S101.3,0.3,79.4,0.3" />
		</svg>
	);
};

export default Logo;
