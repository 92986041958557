import React from 'react';

import { useRouteMatch } from 'react-router-dom';

function anchorWithIssues(path, page, name) {
	return (
		<a className={path === page ? 'current top-nav' : 'top-nav'} href={page}>
			{name}
		</a>
	);
}
const A11yMainNav = () => {
	let { path } = useRouteMatch();

	return (
		<nav aria-label="main">
			<ul>
				<li>{anchorWithIssues(path, '/', 'Home')}</li>
				<li>{anchorWithIssues(path, '/about', 'About US')}</li>
				<li>{anchorWithIssues(path, '/services', 'services')}</li>
				<li>{anchorWithIssues(path, '/partners', 'partners')}</li>
				<li>{anchorWithIssues(path, '/customers', 'customers')}</li>
				<li>{anchorWithIssues(path, '/projects', 'projects')}</li>
				<li>{anchorWithIssues(path, '/careers', 'careers')}</li>
				<li>{anchorWithIssues(path, '/contact', 'contact us')}</li>
			</ul>
		</nav>
	);
};

export default A11yMainNav;
