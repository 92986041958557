import React from 'react';

import AltHeader from '../components/alt-header';
import Aside from '../components/aside';
import Footer from '../components/footer';
import Logo from '../components/svg/logo';
import Home from './alt-routes/home';
import Projects from './alt-routes/projects';
import Contact from './alt-routes/contact';
import Services from './alt-routes/services';
import UserProfile from './userProfile';
import UserArticles from './user-articles';
import UserDetails from './user-details';
import Login from './login';
import Frames from './iframes/frames-top';
import FramesNested from './iframes/frames-nested';
import FramesContent from './iframes/frames-content';

import {
    Route,
    NavLink,
    HashRouter
  } from "react-router-dom";

import '../styles/home.css';

const AltRouter = ({ page = 'home', logout }) => {
	return (
            <HashRouter>
		<div id="top__wrapper">
        <header className="row">
			<div className="row header__top">
				<a href="/" className="logo__link">
					<Logo /> DEMO
				</a>

				<form role="search" className="search__widget">
					<input
						type="search"
						id="search__input"
						placeholder="Search"
						aria-label="search"
						className="searchbox"
					/>
					<button aria-label="search">Go</button>
				</form>
				
			</div>
			<nav aria-label="main">
			<ul>
				<li><NavLink to="/">Home</NavLink></li>
				<li><NavLink to="/about">About US</NavLink></li>
				<li><NavLink to="/services">services</NavLink></li>
				<li><NavLink to="/partners">partners</NavLink></li>
				<li><NavLink to="/customers">customers</NavLink></li>
				<li><NavLink to="/projects">projects</NavLink></li>
				<li><NavLink to="/careers">careers</NavLink></li>
				<li><NavLink to="/contact">contact</NavLink></li>
			</ul>
		</nav>
			<h1>
				<span>{page}</span>
			</h1>
		</header>
			<div className="page__content row">
				<Aside />
				<main>
				<Route path="/" component={Home}/>
                <Route path="alt-router#/projects" component={Projects}/>
                <Route path="/contact" component={Contact}/>
                <Route path="/services" component={Services}/>
				</main>
			</div>
			<Footer />
		</div>
        </HashRouter>
	);
};

export default AltRouter;
