import React, {useContext, useState, useEffect} from 'react';
import { useHistory } from "react-router-dom";

import Layout from '../components/Layout';

import '../styles/user.css';

import {UserContext} from '../App';

import LoginForm from '../components/loginForm';

const Login = (props) => {
    const history = useHistory();
    let isUserLoggedIn = useContext(UserContext);


    const isLoggedIn = isUserLoggedIn.isLoggedIn;
    if(isLoggedIn) {
        history.push('/user');
    }

	return (
		<Layout page="login" logout={props.logout}>
            <h2 style={{marginTop: 0}}>Please Login</h2>
            <LoginForm login={props.login}/>
		</Layout>
	);
};

export default Login;