import React from 'react';
import Layout from '../Layout';

import '../../styles/careers.css';

function Position({position}) {
	return (
        <Layout page={position.position}>
			<section aria-label="Position" className="application">
                <div>
					<img src="http://placeimg.com/700/250/tech" alt="" />
				</div>
                <p>
                    {position.txt}
                </p>
                <form>
                    <caption>Application:</caption>
                    <div className="formInner">
                        <div className="field__wrapper">
                            <label for="firstName">First Name:</label>
                            <input type="text" id="firstName" tabIndex="3" />
                        </div>
                        <div className="field__wrapper">
                            <label for="lastName">Last Name:</label>
                            <input type="text" id="lastName" tabIndex="4    " />
                        </div>
                        <div className="field__wrapper">
                            <label for="email">Email:</label>
                            <input type="email" id="email" tabIndex="1" />
                        </div>
                        <div className="field__wrapper">
                            <label for="resume">Resume:</label>
                            <input type="file" id="resume" tabIndex="2" />
                        </div>
                    </div>
                    <button>Apply Now</button>
                </form>
			</section>
        </Layout>
	);
}

export default Position;
