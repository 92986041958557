import React, {useState, useEffect} from 'react';

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { useHistory } from "react-router-dom";

import Home from './pages/home';
import Projects from './pages/projects';
import Contact from './pages/contact';
import BadContact from './pages/bad-contact';
import Services from './pages/services';
import UserProfile from './pages/userProfile';
import UserArticles from './pages/user-articles';
import UserDetails from './pages/user-details';
import Login from './pages/login';
import Frames from './pages/iframes/frames-top';
import FramesNested from './pages/iframes/frames-nested';
import FramesContent from './pages/iframes/frames-content';
import Careers from './pages/careers';

import AltRouter from './pages/alt-router';


import Map from './pages/map';
export const  UserContext = React.createContext();

function App() {
	const history = useHistory();
	function isLoggedIn() {
		if(typeof window !== 'undefined') {
			const storage = window.localStorage;
			if(storage.userName && storage.password) {
				return true;
			}
		}
		return false;
	}

	let loggingOut = false;
	let loggingIn = false;

	function login(userName, password) {
		if((userName && password)&& (userName.length > 0 && password.length > 0 )) {
			if(typeof window !== 'undefined') {
				const storage = window.localStorage;
				storage.setItem('userName', userName);
				storage.setItem('password', password);
				setUserState({isLoggedIn: isLoggedIn()});
				//setModalState(false);
				
			}
		}
		
	}
	
	function logout() {
		console.log('kjkjkj');
        if(typeof window !== 'undefined') {
            const storage = window.localStorage;
            storage.removeItem('userName');
            storage.removeItem('password');
			setUserState({isLoggedIn: isLoggedIn()});
			loggingOut = true;
        }
    }
	
	const [userState, setUserState] = useState({isLoggedIn: isLoggedIn()});
	
	useEffect(() => {
		
		if(userState.isLoggedIn && loggingOut) {
			loggingOut = false;
			history.push("/");
		} else if(!userState.isLoggedIn && loggingIn) {
			loggingIn = false;
			history.push("/user");
		}
		
	}, [userState.isLoggedIn]);
	

	return (
		<Router>
			<UserContext.Provider value={userState}>
				<PagesManager login={login} logout={logout}/>
			</UserContext.Provider>
		</Router>
	);
}

function PagesManager(props) {
	
	return (
		<Switch>
			<Route path="/alt-router">
				<AltRouter />
			</Route>
			<Route path="/map">
				<Map />
			</Route>
			<Route path="/frames">
				<Frames />
			</Route>
			<Route path="/frames-content">
				<FramesContent />
			</Route>
			<Route path="/frames-nested">
				<FramesNested />
			</Route>
			<Route path="/user">
				<UserProfile logout={props.logout} />
			</Route>
			<Route path="/user-articles">
				<UserArticles logout={props.logout}/>
			</Route>
			<Route path="/user-details">
				<UserDetails logout={props.logout}/>
			</Route>
			<Route path="/login">
				<Login login={props.login} logout={props.logout} />
			</Route>
			<Route path="/contact">
				<Contact logout={props.logout}/>
			</Route>
			<Route path="/bad-contact">
				<BadContact />
			</Route>
			<Route path="/projects">
				<Projects logout={props.logout} />
			</Route>
			<Route path="/services">
				<Services logout={props.logout} />
			</Route>
			<Route path="/careers">
				<Careers />
			</Route>
			<Route path="/customers">
				<Home page="customers" logout={props.logout} />
			</Route>
			<Route path="/partners">
				<Home page="partners" logout={props.logout} />
			</Route>
			<Route path="/about">
				<Home page="about" logout={props.logout} />
			</Route>
			<Route path="/">
				<Home logout={props.logout} />
			</Route>
		</Switch>
	);
}

export default App;
