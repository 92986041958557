import React from 'react';
import { BrowserRouter as Switch, Route, useParams, useRouteMatch } from 'react-router-dom';

import PositionsList from '../components/careers/PositionsList';
import Position from '../components/careers/Position';

import '../styles/careers.css';

const Positions = [
    {
        id: 'HCC',
        position: 'Head of Call Center',
            url: '/careers/HCC',
            txt: "You'll be rewarded with; competitive salary and benefits, room to grow and develop yourself including training, a stunning central London location.",
            order: 4
        },
        
        {
            id: 'CEO',
            position: 'Chif Executive Officer',
            url: '/careers/CEO',
            txt: "We turn on equity and have no other investors, which means our feet are pretty set into the ground and we can do whatever we want with our money from incentive trips, to sponsoring your own workshop!",
            order: 1
        },
        {
            id: 'AM',
            position: 'Accounting Manager',
            txt: "These roles come with a heavy focus on solving problems through innovation and helping our digital teams to deliver stellar outcomes.",
            url: '/careers/AM',
            order: 3
        },
        {
            id: 'recruiter',
            position: 'Recruiter',
            txt: "One of the companies I am working with is one of the world's leading providers of communications services and solutions, serving customers in more than 170 countries.",
            url: '/careers/recruiter',
            order: 7
        },
        {
            id: 'DA',
            position: 'Data Analist',
            txt: "As software engineer you will work on the front end using JavaScript, HTML5 and CSS3 to improve the enterprise software/platforms by adding new functionalities.",
            url: '/careers/DA',
            order: 8
        },
        {
            id: 'RM',
            position: 'Resource Manager',
            txt: "No world class product has been built without passionate engineers solving complex and seemingly impossible user problems, hence why I’m reaching out to you.",
            url: '/careers/RM',
            order: 5
        },
        {
            id: 'CMO',
            position: 'Chif Marketing Officer',
            txt: "One of the companies I am working with is one of the world's leading providers of communications services and solutions, serving customers in more than 170 countries. Our client is looking for an Angular developer to join their deployment team in a large project they are undertaking.",
            url: '/careers/CMO',
            order: 2
        },
        {
            id: 'OE',
            position: 'Operations Engeneer',
            txt: "You'll be rewarded with; competitive salary and benefits, room to grow and develop yourself including training, a stunning central London location, a great environment with intelligent, driven and passionate colleagues delivering excellence on a daily basis, and will always work with cutting edge technologies.",
            url: '/careers/OE',
            order: 9
        },
        {
            id: 'AS',
            position: 'Accessibility Spcialist',
            txt: "These roles come with a heavy focus on solving problems through innovation and helping our digital teams to deliver stellar outcomes.",
            url: '/careers/AS',
            order: 6
        }

    ]

function Careers() {
	let { path } = useRouteMatch();
    
	return (
        
		<Switch>
			<Route exact path={path}>
				<CareerPages />
			</Route>
			<Route path={`${path}/:ID`}>
				<CareerPages />
			</Route>
		</Switch>
	);
}

function CareerPages() {
    let { ID } = useParams();
    const positionDetails = Positions.filter((position) => position.id === ID)
	return  ID ? <Position position={positionDetails[0]} /> : <PositionsList positions={Positions} />
}

export default Careers;
