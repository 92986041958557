import React from 'react';

import Layout from '../Layout';


function PositionsList(props) {
	return (
		<Layout page='Careers'>
			<section aria-label="Positions list">
				<div className='positions__wrapper'>
				{
					props.positions.map((position => (
						<div className='position__box' style={{order: position.order}} key={position.position}>
							<h3>{position.position}</h3>
							<p>
								{position.txt}
							</p>
							<a href={position.url}>Apply Now</a>
						</div>
					)))
				}
				</div>
			</section>
			</Layout>
	);
}

export default PositionsList;
