import React, {useState, useContext} from 'react';

import Header from './header';
import Aside from './aside';
import Modal from './modal';
import Footer from './footer';

import '../styles/_base.css';

import {UserContext} from '../App';

const Layout = ({ children, page, loginRequiered, logout }) => {
	
	const [modalState, setModalState] = useState(false);
	const isLoggedIn = useContext(UserContext).isLoggedIn;

	const Content = ({children}) => {
		if(loginRequiered && !isLoggedIn) {
			return <h2>You must be logged in to view content on this page</h2>
		} else {
			return children
		}
	}
	function toggleModal() {
		setModalState(!modalState)
	};
	return (
		<div id="top__wrapper">
			<div id="skip-links__wrapper">
				<a className="skip__link" href="#page-content">
					Skip to main content
				</a>
			</div>

			<Header page={page} toggleModal={toggleModal} logout={logout} />
			<div className="page__content row">
				<Aside />
				<main>
				<Content children={children} />
				</main>
			</div>
			<Footer />
			<Modal isOpen={modalState} toggleModal={toggleModal} />
		</div>
	);
};

export default Layout;
